import moment from 'moment';

// http://stat.e-kharkiv.org
export const PERSONAL_STATISTIC_SERVER_ADDRES = '/api/statistics/amount/';
export const ORGANISATION_STATISTIC_SERVER_ADDRES = '/api/statistics/company/amount/';
export const USER_AUTHENTICATION_SERVER_ADDRES = '/api/statistics/user/';
export const dateToString = ( date ) => moment( date ).format( 'DD.MM.YYYY' );
export const stylesActivityIndicator = theme => ({
  progress: {
    margin: theme.spacing.unit * 2,
  },
});
export const stylesCheckboxList = theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
});
export const personalTableInfo = {
        tableHeaderValues: ['Номер', 'ФИО', 'Организация', 'Заявлений', 'Ответов', 'Отказов',
            'Прерванная сессия', 'Довідка 1', 'Довідка 2', 'Довідка 3', 'Довідка 4', 'Довідка 5', 'Довідка 6',
            'Довідка 7', 'Довідка 8', 'Довідка 9', 'Довідка 10', 'Довідка 11', 'E-картка', 'Актуалізація фізлица',
        'Актуалізація органи самоврядування', 'Cтатистика КП', 'Перевірка у РБС', 'Зняття з реєстрації',
            'Формування законним представником', "Картка Харків'янина"]
        };
export const organisationTableInfo = {
                tableHeaderValues: ['Номер','Организация', 'Заявления', 'Ответы', 'Отказы',
        'Прерванная сессия', 'Довідка 1', 'Довідка 2', 'Довідка 3', 'Довідка 4', 'Довідка 5', 'Довідка 6',
                    'Довідка 7', 'Довідка 8', 'Довідка 9','Довідка 10', 'Довідка 11', 'E-картка', 'Актуалізація фізлица',
        'Актуалізація органи самоврядування', 'Cтатистика КП', 'Перевірка у РБС', 'Зняття з реєстрації',
                    'Формування законним представником', "Картка Харків'янина"]
        };

export const referenceNames = ['Довідка про реєстрацію місця проживання особи',
            'Довідка про осіб, місце проживання (перебування, проживання/перебування) яких\n' +
            '                        зареєстровано за адресою нерухомого майна разом із відповідною особою станом на конкретну дату',
            'Довідка про осіб, місце проживання (перебування, проживання/перебування) яких\n' +
            '                        зареєстровано за адресою нерухомого майна', 'Довідка про місце проживання (перебування, проживання/перебування) особи',
            'Довідка про місце проживання(перебування, проживання/перебування) особи за\n' +
            '                        відповідною адресою нерухомого майна', 'Довідка о зареєстрованих у житловому приміщенні осіб (довідка про склад сім\'ї)',
            'Довідка про зареєстрованих у житловому приміщенні осіб (довідка про склад сім’ї) з заявою',
            'Довідка про зареєстрованих у житловому приміщенні осіб (довідка про склад сім’ї)  (Комунальні підприємства)',
            'Довідка про зареєстрованих у житловому приміщенні осіб (довідка про склад сім’ї)  (фізичні особи)',
            'Довідка про склад сім’ї зі змінами зареєстрованих осіб',
            'Довідка про склад сім’ї зі змінами зареєстрованих осіб з заявою'];
import React, { Component } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css'
import moment from 'moment';
import { dateToString } from "./constants/constants.jsx";

class RangePicker extends React.Component {
    constructor( props ){
        super( props );
        this.state = {
            startDate: moment(),
            endDate: moment(),
            startDateVisible: dateToString(),
            endDateVisible: dateToString()
        }
        this.handleApply = this.handleApply.bind( this );
    }
    handleApply( event,picker ){
        let startDate = picker.startDate._d;
        let endDate = picker.endDate._d;
        let startDateVisible = dateToString( startDate );
        let endDateVisible = dateToString( endDate );
        this.setState(
            { startDate: moment( startDate ),
                endDate: moment( endDate ),
                startDateVisible: startDateVisible,
                endDateVisible: endDateVisible
            })
        this.props.dateChange( startDateVisible, endDateVisible )
    }
  render() {
        let datepickerValue = ( this.state.startDateVisible != this.state.endDateVisible ) ?
            `${ this.state.startDateVisible } - ${ this.state.endDateVisible }` :
            `${ this.state.startDateVisible }`;
    return (
      <DateRangePicker startDate = { this.state.startDate } endDate = { this.state.startDate }
                       onApply = { this.handleApply } >
          <input value = { datepickerValue } readOnly/>
      </DateRangePicker>
    );
  }
}

export default RangePicker;
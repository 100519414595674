import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { stylesCheckboxList } from "./constants/constants.jsx";

class CheckboxList extends React.Component {
  constructor( props ){
    super( props );
    this.state = {
      checked: [],
        referenceNames: ['Довідка про реєстрацію місця проживання особи',
            'Довідка про осіб, місце проживання (перебування, проживання/перебування) яких\n' +
            '                        зареєстровано за адресою нерухомого майна разом із відповідною особою станом на конкретну дату',
            'Довідка про осіб, місце проживання (перебування, проживання/перебування) яких\n' +
            '                        зареєстровано за адресою нерухомого майна', 'Довідка про місце проживання (перебування, проживання/перебування) особи',
            'Довідка про місце проживання(перебування, проживання/перебування) особи за\n' +
            '                        відповідною адресою нерухомого майна', 'Довідка о зареєстрованих у житловому приміщенні осіб (довідка про склад сім\'ї)',
            'Довідка про зареєстрованих у житловому приміщенні осіб (довідка про склад сім’ї) з заявою',
            'Довідка про зареєстрованих у житловому приміщенні осіб (довідка про склад сім’ї)  (Комунальні підприємства)',
        'Довідка про зареєстрованих у житловому приміщенні осіб (довідка про склад сім’ї)  (фізичні особи)',
                        'Довідка про склад сім’ї зі змінами зареєстрованих осіб',
            'Довідка про склад сім’ї зі змінами зареєстрованих осіб з заявою', 'E-картка', 'Актуалізація фізлица',
        'Актуалізація органи самоврядування', 'Cтатистика КП', 'Перевірка у РБС', 'Зняття з реєстрації',
            'Формування законним представником', "Картка Харків'янина"]
    };
    this.handleToggle = this.handleToggle.bind( this );
  }

  handleToggle( value ) {
    const { checked } = this.state;
    const currentIndex = checked.indexOf( value );
    const newChecked = [...checked];
    const booleanValues = [false,false,false,false,false,false, false, false, false, false, false, false, false, false,
        false, false, false, false, false];

    switch (currentIndex) {
        case -1:
          newChecked.push(value);
          break;
        default:
          newChecked.splice( currentIndex, 1 );
          break;
    }
    this.setState({
      checked: newChecked,
    });

    newChecked.forEach(item => {
      booleanValues[item] = true;
    });
    this.props.columnVisibilityChange( booleanValues );
  };

  render() {
    const { classes } = this.props;
    return (
      <div className = { classes.root } >
        <List >
          { this.state.referenceNames.map(( item, index ) => (
            <ListItem
              key = { index }
              role = { undefined }
              dense
              button
              onClick = { () => this.handleToggle( index ) }
              className = { classes.listItem }
            >
              <Checkbox
                checked = { this.state.checked.indexOf( index ) !== -1 }
                tabIndex = { -1 }
                disableRipple
              />
                {item === 'E-картка'? <ListItemText primary={item}/>:<ListItemText primary = { `Довідка ${ index + 1 } - ${ item }` }/>}
            </ListItem>
          ))}
        </List>
      </div>
    );
  }
}

CheckboxList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(stylesCheckboxList)(CheckboxList);
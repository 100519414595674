import React from 'react';
import { FormGroup, ControlLabel, FormControl, Button } from 'react-bootstrap'
import '../css/loginForm.css';
import md5 from 'js-md5';
import { USER_AUTHENTICATION_SERVER_ADDRES } from "./constants/constants.jsx";

class LoginForm extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.authenticate = this.authenticate.bind(this);
    this.processResponse = this.processResponse.bind(this);
  }
  handleSubmit(e) {
      e.preventDefault();
      let login = document.querySelector('input[name=email]').value || 'test';
      let password = md5(document.querySelector('input[name=password]').value) || 'test';
      this.authenticate(login, password);
  }
    authenticate(login, password, cb) {
        fetch( USER_AUTHENTICATION_SERVER_ADDRES  + login + '/' + password )
            .then( response => response.json() )
            .then( data => this.processResponse( data ) );
    }
    processResponse( data ){
      if (data.data.doesExist) {
          localStorage.setItem('userName', data.data.name);
          localStorage.setItem('login', data.data.login);
          this.props.setAuthentication(true);
      } else {
          if (!document.getElementById('errorMessage')){
              let inputs = document.querySelectorAll('input');
              let errorMessage = document.createElement('span');
              errorMessage.id = 'errorMessage';
              errorMessage.innerHTML = 'Введен неверный логин или пароль, пожалуйста, попробуйте заново';
              errorMessage.style.color = 'red';
              inputs[1].parentElement.appendChild(errorMessage);
          }
      }
    }
  render() {
    return (
      <form  onSubmit={this.handleSubmit}>
        <FormGroup
          controlId="email"
        >
          <ControlLabel>E-mail</ControlLabel>
          <FormControl
            type="email"
            name='email'
            placeholder="Введите e-mail"
            defaultValue={localStorage.getItem('login')? localStorage.getItem('login'):' '}
          />
        </FormGroup>
          <FormGroup controlId='password'>
            <ControlLabel>Пароль</ControlLabel>
            <FormControl
                name='password'
                type='password'
                placeholder="Введите пароль"
            />
        </FormGroup>
          <Button type="submit">Подтвердить</Button>
      </form>
    );
  }
}

export default LoginForm;
import React from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { dateToString } from "./constants/constants.jsx";

export default class FormattedDateString extends React.Component {
  constructor( props ) {
    super( props )
    this.state = {
      startDate: moment()
    };
    this.handleChange = this.handleChange.bind( this );
  }
  handleChange( date ) {
    this.setState({
      startDate: date
    });
    this.props.dateChange( dateToString( date._d ) );

  }
  render() {
    return <div>
        <DatePicker selected = { this.state.startDate } onChange = { this.handleChange }/>
              </div>
  }
}
import React from "react";
import { Jumbotron } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

class ErrorMessage extends React.Component{
  render(){
    return <Jumbotron>
            <h1>Данные не получены</h1>
            <p>
              К сожалению, по данной дате нет сформированных справок. Попробуйте выбрать другую.
            </p>

          </Jumbotron>
  }
}

export default ErrorMessage
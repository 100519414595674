import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import '../css/NavBarStyle.css';

const NavBar = (props) => {
    let setFalseAuthentication = props.changeAuthentication;
    let signout = (props) => {
        setFalseAuthentication(false);
        localStorage.removeItem("userName");
        localStorage.removeItem("login");
    }
    let userName = localStorage.getItem('userName');
    let pageNavigationField = <AppBar position = "static">
            <Toolbar style={{'position':'relative'}}>
                <Typography variant = "subheading" color = "default">
                    {userName}
                </Typography>
                <Typography variant = "subheading" color = "inherit">
                    <Link to = '/' style={{'color':'white', 'textDecoration':'none'}}>Страница статистики</Link>
                </Typography>
                <Typography variant = 'subheading' color = 'inherit'>
                    <Link to = '/personal' style={{'color':'white', 'textDecoration':'none'}}>Личная статистика</Link>
                </Typography>
                <Typography variant = 'subheading' color = 'inherit'>
                    <Link to = '/organisation' style={{'color':'white', 'textDecoration':'none'}}>Статистика по организациям</Link>
                </Typography>
                <Typography variant = 'subheading' color = 'inherit' style={{'display':'block',
                    'position':'absolute', 'top': '10px', "right":'50px', 'cursor':'pointer'}}
                onClick={signout}>
                    Выход
                </Typography>
            </Toolbar>
        </AppBar>
    let loginNavigationField = <AppBar position = "static">
            <Toolbar style={{'position':'relative'}}>
                <Typography variant = "headline" color = "inherit">
                    Авторизация
                </Typography>
            </Toolbar>
        </AppBar>
    let navigationField = localStorage.getItem('userName')? pageNavigationField: loginNavigationField;

      return <div>
          { navigationField }
        </div>;
}

export default NavBar;
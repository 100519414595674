import React from "react";
import NavBar from './js/NavBar.jsx';
import Main from './js/Main.jsx';

class App extends React.Component {
    constructor(props){
        super(props);
        let authenticate = localStorage.getItem("userName") && localStorage.getItem("login");
        this.state = {
            isAuthenticated: authenticate
        }
        this.changeAuthentication = this.changeAuthentication.bind(this);
    }
    changeAuthentication(value){
        this.setState({isAuthenticated: value})
    }
    render () {
        return <div>
                 <NavBar changeAuthentication={this.changeAuthentication}/>
                <Main authentication={this.state.isAuthenticated} changeAuthentication={this.changeAuthentication}/>
            </div>
    }
}

export default App;

import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { ORGANISATION_STATISTIC_SERVER_ADDRES } from './constants/constants.jsx';

const StatisticTable = ( props ) => {
    let unhiddenColumnsNumber = props.columns.tableHeaderValues.length - 19;
    let tableHeaderColumnData;
    let bodyData;
    let totalStatements = 0;
    let totalReplies = 0;
    let totalRefusal = 0;
    let totalInterruptedSession = 0;
    tableHeaderColumnData = props.columns.tableHeaderValues.map(( item, index ) => {
        if( props.serverAddress == !ORGANISATION_STATISTIC_SERVER_ADDRES && index == 2) {
                return <TableHeaderColumn
                            key={ index }
                            width='150'
                            dataField={ item }
                            filter={ { type: 'TextFilter', delay: 500 } }
                            columnTitle>
                            { item }
                        </TableHeaderColumn>
        }
        if ( index == 0 ) {
            return <TableHeaderColumn
                        key={ index }
                        isKey
                        width='150'
                        dataField={ item }
                        columnTitle>
                        { item }
                    </TableHeaderColumn>
        } else if ( index == 1 ) {
            return <TableHeaderColumn
                        key={ index }
                        width='150'
                        dataField={ item }
                        filter={ { type: 'TextFilter', delay: 500 } }
                        columnTitle>
                        { item }
                    </TableHeaderColumn>
        } else if ( index >= unhiddenColumnsNumber ){
           return <TableHeaderColumn
                        key={ index }
                        width='150'
                        dataField={ item }
                        hidden={ !props.columnVisibility[index - unhiddenColumnsNumber] }
                        columnTitle>
                    { item }
                    </TableHeaderColumn>
        } else {
            return <TableHeaderColumn
                        key={ index }
                        width='150'
                        dataField={ item }
                        columnTitle>
                        { item }
                    </TableHeaderColumn>
        }
    });
    let convertRowData = ( arr ) => {
        let newRowValues = {};
        arr.forEach(( item, index ) => {
            if ( props.serverAddress == ORGANISATION_STATISTIC_SERVER_ADDRES ) {
                switch ( index ){
                    case 2:
                        totalStatements += item;
                        break;
                    case 3:
                        totalReplies += item;
                        break;
                    case 4:
                        totalRefusal += item;
                        break;
                    case 5:
                        totalInterruptedSession += item;
                        break;
                }
            }
            item = ( item === null )? 'Физлица': item;
           let fieldName = props.columns.tableHeaderValues[index];
           newRowValues[fieldName] = item;
        });
        return newRowValues;
    };
    let addTotalRow = () => {
        let totalRow = {};
        props.columns.tableHeaderValues.forEach(( item, index ) =>{
            let fieldName = item;
            switch( index ){
                case 1:
                    totalRow[fieldName] = 'Усього';
                    break;
                case 2:
                    totalRow[fieldName] = totalStatements;
                    break;
                case 3:
                    totalRow[fieldName] = totalReplies;
                    break;
                case 4:
                    totalRow[fieldName] = totalRefusal;
                    break;
                case 5:
                    totalRow[fieldName] = totalInterruptedSession;
                    break;
                default:
                    totalRow[fieldName] = '';
            }
        })
        bodyData.push(totalRow);
    }

    bodyData = props.rows.map(( item,index ) =>{
        return convertRowData( item );
    })
    props.serverAddress == ORGANISATION_STATISTIC_SERVER_ADDRES ? addTotalRow() : '';
    let tableHeight = screen.height * 0.77;
    return (
        <BootstrapTable id='futurexlstable' data={ bodyData } height = {tableHeight} scrollTop = { 'Top' }  striped
            options={ { noDataText: 'К сожалению, не удалось получить данные', exportCSVText: 'Сохранить в CSV' } }>
              { tableHeaderColumnData }
        </BootstrapTable>
    );
};

export default StatisticTable;




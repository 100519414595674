import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { stylesActivityIndicator } from "./constants/constants.jsx";

function ActivityIndicator(props) {
  const { classes } = props;
  return (
    <div>
      <CircularProgress className = { classes.progress } size = { 100 } left = { -20 } top = { 10 }
                        style = {{ marginLeft: '50%' }}/>
    </div>
  );
}

ActivityIndicator.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(stylesActivityIndicator)(ActivityIndicator);

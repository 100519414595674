import { Switch, Route } from 'react-router-dom';
import React from 'react';
import StatisticPage from "./StatisticPage.jsx";
import {PERSONAL_STATISTIC_SERVER_ADDRES,ORGANISATION_STATISTIC_SERVER_ADDRES} from './constants/constants.jsx';
import StatisticChart from './StatisticChart.jsx';
import { personalTableInfo, organisationTableInfo } from './constants/constants.jsx';
import LoginForm from './LoginForm.jsx';

const WrappedPersonalStatisticPage = function( props ){
    return (<StatisticPage { ...props } columns = { personalTableInfo } serverAddress = { PERSONAL_STATISTIC_SERVER_ADDRES }/>)
};
const WrappedOrganisationStatisticPage = function( props ){
    return (<StatisticPage { ...props } columns = { organisationTableInfo } serverAddress = { ORGANISATION_STATISTIC_SERVER_ADDRES }/>)
};

class Main extends React.Component{
    constructor(props){
        super(props);
        this.setAuthentication = this.setAuthentication.bind(this);
    }
    setAuthentication(value) {
        this.props.changeAuthentication(value);
    }
    render(){
        let authentication = this.props.authentication ? <Switch>
                <Route exact path='/' component = { StatisticChart }/>
                <Route exact path='/personal' component = { WrappedPersonalStatisticPage }/>
                <Route exact path='/organisation' component = { WrappedOrganisationStatisticPage }/>
            </Switch> :
            <LoginForm setAuthentication={this.setAuthentication}/>
        return <main>
            {authentication}
          </main>
    }
}

export default Main;
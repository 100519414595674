import React from "react";
import FormattedDateString from './FormattedDateString.jsx';
import ActivityIndicator from './ActivityIndicator.jsx';
import StatisticTable from './StatisticTable.jsx';
import CheckboxList from './CheckboxList.jsx';
import { ORGANISATION_STATISTIC_SERVER_ADDRES, dateToString, referenceNames } from './constants/constants.jsx';
import RangePicker from './RangePicker.jsx';
import DownloadButton from './DownloadButton.jsx';
import ContainedButton from './ContainedButton.jsx';
import { Button } from 'react-bootstrap';

class StatisticPage extends React.Component{
    constructor( props ) {
        super( props );
        this.state = {
            tableRows: [],
            isLoading: false,
            tableDate: dateToString,
            columnVisibility: [false, false, false, false, false, false, false,
                false, false, false, false, false, false, false, false, false, false, false, false],
            multiDataSet: null,
            listVisibility: false
        };
        this.dateChange = this.dateChange.bind( this );
        this.fetchServerData = this.fetchServerData.bind( this );
        this.columnVisibilityChange = this.columnVisibilityChange.bind( this );
        this.saveTableData = this.saveTableData.bind( this );
        this.handleShowChange = this.handleShowChange.bind( this );

    }
    handleShowChange(){
        this.setState({ listVisibility: !this.state.listVisibility })
    }
    processRespone(json) {
        if ( json.status === 0 ) {
            this.setState({ tableRows: json.rows, isLoading: false });
        } else {
            this.setState({ tableRows: [], isLoading: false });
        }
    }
    fetchServerData ( startDate, endDate ) {
        this.setState({ isLoading: true });
        if (this.props.serverAddress == ORGANISATION_STATISTIC_SERVER_ADDRES) {
            fetch( this.props.serverAddress + startDate + '/' + endDate )
            .then( response => response.json() )
            .then( data => this.processRespone( data ) );
        } else {
            fetch( this.props.serverAddress + startDate )
            .then( response => response.json() )
            .then( data => this.processRespone( data ) );
        }
    };
    columnVisibilityChange( data ){
        this.setState( {columnVisibility: data} );
    }
    dateChange( startDate, endDate = '' ){
        this.fetchServerData( startDate, endDate );
    }
    componentDidMount() {
        this.fetchServerData( dateToString(), dateToString());
    }
    saveTableData(){
        let tableHeader = document.querySelector('.table-hover thead tr');
        let tableBody = document.querySelector('.table-striped tbody');
        let tableHeaderValues = this.getHeaderData(tableHeader.children);
        let tableBodyValues = this.getBodyData(tableBody.children);
        let multiDataSet = [{
            columns:tableHeaderValues,
            data:tableBodyValues
        }];
        this.setState({multiDataSet: multiDataSet}, () => {
            this.setState({multiDataSet: null})
        })
    }
    getBodyData( htmlCollection ) {
        let bodyRows = [];
        for (var i = 0; i < htmlCollection.length; i++) {
            let bodyRow = [];
            for (var j = 0; j < htmlCollection[i].children.length; j++) {
                if (htmlCollection[i].children[j].style.display != 'none') {
                    if (this.props.serverAddress == ORGANISATION_STATISTIC_SERVER_ADDRES && i == (htmlCollection.length - 1) && (j === 0 || j > 5)) {
                        bodyRow.push(htmlCollection[i].children[j].innerText);
                    } else if (j == 1 || (j == 2 && this.props.serverAddress != ORGANISATION_STATISTIC_SERVER_ADDRES)) {
                        bodyRow.push(htmlCollection[i].children[j].innerText);
                    } else {
                        bodyRow.push(+(htmlCollection[i].children[j].innerText));
                    }
                }
            }
            bodyRows.push(bodyRow)
        }
        return bodyRows;
    }
    getHeaderData( htmlCollection ){
        let tableColumns = [];
        for (var i = 0; i < htmlCollection.length; i++) {
            if (htmlCollection[i].style.display != 'none') {
                let text = htmlCollection[i].innerText.trim();
                if (text.indexOf('Довідка') != -1) {
                    let referenceNumber = text.split(' ')[1];
                    tableColumns.push(referenceNames[referenceNumber - 1]);
                } else {
                    tableColumns.push(text);
                }
            }
        }
        return tableColumns;
    }
    render () {
        const { tableRows, isLoading, columnVisibility, multiDataSet, listVisibility } = this.state;
        let datepicker = ( this.props.serverAddress == ORGANISATION_STATISTIC_SERVER_ADDRES ) ?
            <RangePicker dateChange = { this.dateChange } /> :
            <FormattedDateString dateChange = { this.dateChange }/>
        let listBlock = listVisibility?
            <CheckboxList columnVisibilityChange = { this.columnVisibilityChange }/>:
            '';
        let buttonText = listVisibility? 'Свернуть список справок': "Показать список справок";
        return <div>
                <Button onClick={this.handleShowChange} bsStyle="info" style={{"margin": '10px'}}>{ buttonText }</Button>
                { listBlock }
                <br/>
                { datepicker }
                <ContainedButton saver = { this.saveTableData }/>
                {this.state.multiDataSet != null ?
                    <DownloadButton multiDataSet = { multiDataSet }/>
                    :
                        null
                }
                 { isLoading ?
                    <div style = {{ position: 'relative' }}>
                       <ActivityIndicator/>
                    </div> :
                     <StatisticTable rows = { tableRows } columns = { this.props.columns }
                                     columnVisibility = { columnVisibility }
                                     serverAddress = { this.props.serverAddress == ORGANISATION_STATISTIC_SERVER_ADDRES ?
                                     ORGANISATION_STATISTIC_SERVER_ADDRES : ''}/>
                 }
            </div>

    }
}
export default StatisticPage;

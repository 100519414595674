import React from "react";
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

class DownloadButton extends React.Component {
    render() {
        return (
            <ExcelFile hideElement={true}>
                <ExcelSheet dataSet={this.props.multiDataSet} name="Organization"/>
            </ExcelFile>
        );
    }
}

export default DownloadButton;
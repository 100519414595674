import React, { Component } from "react";
import Chart from 'chart.js';
import 'react-datepicker/dist/react-datepicker.css';
import ActivityIndicator from './ActivityIndicator.jsx';
import { ORGANISATION_STATISTIC_SERVER_ADDRES, dateToString } from './constants/constants.jsx';
import ErrorMessage from './ErrorMessage.jsx';
import RangePicker from './RangePicker.jsx';


class StatisticChart extends React.Component {
    constructor( props ) {
        super( props );
        this.state = {
            myChart: undefined,
            isLoading: false,
            errorMessage: false
        };
        this.fetchServerData = this.fetchServerData.bind( this );
        this.dateChange = this.dateChange.bind( this );
        this.convertData = this.convertData.bind( this );
        this.updateCanvas = this.updateCanvas.bind( this );
    }
    randomRgba( transparency ) {
        let o = Math.round;
        let r = Math.random;
        let s = 255;
        return 'rgba(' + o(r()*s) + ',' + o(r()*s) + ',' + o(r()*s) + ',' + transparency + ')';
    }
    dateChange( startDate = dateToString(), endDate = dateToString() ){
        this.fetchServerData( startDate, endDate );
    }
    convertData( data ){
        if (data.status == -1 ){
            this.setState({ isLoading: false, errorMessage: true });
            return
        }
        let organisationName = [];
        let referenceQuantity = [];
        let backgroundColors = [];
        let borderColors = [];
        let convertedData = {};
        data.rows.forEach(( item, index ) => {
            backgroundColors.push( this.randomRgba( 0.2 ) );
            borderColors.push( backgroundColors[index].replace(/-?\d+\.\d+/g, "1") );
            item.forEach(( i, ind ) => {
                if ( ind == 1 ) {
                    if ( i == null ) {
                        organisationName.push( 'Физлицо' );
                    } else {
                        organisationName.push( i );
                    }
                } else if ( ind == 3 ) {
                    referenceQuantity.push( i );
                }
            })
        });
        convertedData.organisationNames = organisationName;
        convertedData.referenceQuantity = referenceQuantity;
        convertedData.backgroundColors = backgroundColors;
        convertedData.borderColors = borderColors;
        this.setState({ isLoading: false });
        this.updateCanvas( convertedData );
    }
    fetchServerData ( startDate, endDate ) {
        this.setState({ isLoading: true, errorMessage: false });
        fetch( ORGANISATION_STATISTIC_SERVER_ADDRES + startDate + '/' + endDate)
            .then( response => response.json() )
            .then( data => this.convertData( data ) );
    };
    componentDidMount() {
        this.dateChange();
    }
    updateCanvas(data){
        const canvas = document.getElementById( 'canvas' );
        const ctx = canvas.getContext( '2d' );
        ctx.clearRect( 0,0, canvas.width, canvas.height );
        if( this.state.myChart ) {
            this.state.myChart.destroy();
        }
        this.state.myChart = new Chart(ctx, {

            type: 'doughnut',
            data: {
                labels: data.organisationNames,
                datasets: [{
                    data: data.referenceQuantity,
                    backgroundColor: data.backgroundColors,
                    borderColor: data.borderColors,
                    borderWidth: 1
                }]
            }
        });
    }
    render() {
        let { isLoading, errorMessage } = this.state;
        let errorContainer = errorMessage ? <ErrorMessage/> : null
        return (
            <div>
                <RangePicker dateChange = { this.dateChange }/>
                {errorContainer}
                <div>
                    {isLoading?
                     <div style = {{ position: 'relative' }}>
                       <ActivityIndicator/>
                    </div>:
                      <canvas id = "canvas" />}
                </div>
            </div>
        );
    }
}

export default StatisticChart;

